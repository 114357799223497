import HoraCharla from './HoraCharla'
import DetalleCharla from './DetalleCharla'
import Disertante from './Disertante'
import clock from '../img/clock.svg'
import '../App.css'
import { Link } from 'react-router-dom'


const ItinerarioContainer = () => {
    return (
    <div className='mb-6'>
        <div className='mb-4 mt-6'>
            <div className="mt-4 poppins-bold gridPrograma">
            <img src={clock} style={{maxWidth: '1.8rem', margin:'auto'}}/>
                <p className='color is-size-4-desktop is-size-6-mobile'>Martes 11 de abril</p>
             </div>
        </div>

        <div className='gridPrograma'>
            <HoraCharla hora={'08:00'}/>
            <div>
                <DetalleCharla tema={'Desayuno.'} />
            </div>
        </div>


        <div className='gridPrograma'>
            <HoraCharla hora={'09:00'}/>
            <div>
                <DetalleCharla tema={'Acreditaciones.'} />
            </div>
        </div>


        <div className='gridPrograma'>
            <HoraCharla hora={'11:00'}/>
            <div>
                <DetalleCharla tema={'Apertura del Congreso y Nominación de la Comisión de Poderes.'} />
                <Disertante disertante={'Invitados especiales:'} cargo={'Ministro de Educación, Jaime Perczyk; Secretario de Políticas Universitarias, Oscar Alpa; Subsecretario de Políticas Universitarias, Daniel López; Subsecretario de Fortalecimiento de Trayectorias Estudiantiles, Leandro Quiroga; Vicepresidente del CIN, Carlos Greco; y Rectores de Córdoba, Jhon Boretto; Villa María, Luis Negretti; Patagonia Austral, Hugo Santos Rojas; Luján, Walter Panessi; y José C. Paz, Darío Kusinsky.'}/><br></br>
            </div>
        </div>


        <div className='gridPrograma'>
            <HoraCharla hora={'12:00'}/>
            <div>
                <DetalleCharla tema={'Inauguración del Spa y la Sala Híbrida del Hotel Evita.'} />
            </div>
        </div>


        <div className='gridPrograma'>
            <HoraCharla hora={'13:00'}/>
            <div>
                <DetalleCharla tema={'Almuerzo.'} />
            </div>
        </div>


        <div className='gridPrograma'>
            <HoraCharla hora={'15:00'}/>
            <div>
                <DetalleCharla tema={'Puntos 1, 2, 3, 4, 5 y 6.'} />
                <p>1- Lectura del Dictamen de la Comisión de Poderes.</p>
                <p>2- Elección de dos Secretarías del Congreso.</p>
                <p>3- Elección de dos Congresales para firmar el Acta del Congreso.</p>
                <p>4- Lectura y Consideración del Acta del Congreso Anterior.</p>
                <p>5- Lectura y Consideración de la Memoria y el Balance del período 01/01/2022 al 31/12/2022.</p>
                <p>6- Elección de Autoridades de la Junta Electoral.</p>
            </div>
        </div>


        <div className='gridPrograma'>
            <HoraCharla hora={'17:30'}/>
            <div>
                <DetalleCharla tema={'Merienda.'} />
            </div>
        </div>


        <div className='gridPrograma'>
            <HoraCharla hora={'18:00'}/>
            <div>
                <DetalleCharla tema={'Puntos 8 y 9.'} />
                <p>8. Análisis y situación de las UUNN. Actualidad Nacional.</p>
                <p>9. Convenio Colectivo de Trabajo. Consideraciones Generales.</p>
            </div>
        </div>


        <div className='gridPrograma'>
            <HoraCharla hora={'21:00'}/>
            <div>
                <DetalleCharla tema={'Cena.'} />
            </div>
        </div>



        <div className='mb-4 mt-6'>
            <div className="mt-4 poppins-bold gridPrograma">
                <img src={clock} style={{maxWidth: '1.8rem', margin:'auto'}}/>
                <p className='color is-size-4-desktop is-size-6-mobile'>Miércoles 12 de abril</p>
             </div>
        </div>


        <div className='gridPrograma'>
            <HoraCharla hora={'8:00'}/>
            <div>
                <DetalleCharla tema={'Desayuno.'} />
            </div>
        </div>



        <div className='gridPrograma'>
            <HoraCharla hora={'10:00'}/>
            <div>
                <DetalleCharla tema={'Puntos 10 y 11.'} />
                <p>10. Informe General de la Mesa Ejecutiva.</p>
                <p>11. Ponencias de las Asociaciones.</p>
            </div>
        </div>



        <div className='gridPrograma'>
            <HoraCharla hora={'13:00'}/>
            <div>
                <DetalleCharla tema={'Almuerzo.'} />
            </div>
        </div>


        <div className='gridPrograma'>
            <HoraCharla hora={'15:00'}/>
            <div>
                <DetalleCharla tema={'Puntos 12, 13 y 14.'} />
                <p>12. Informe General Obra Social.</p>
                <p>13. Informe Complementario del Consejo Directivo.</p>
                <p>14. Designación de Miembros del Consejo Directivo.</p>
            </div>
        </div>


        <div className='gridPrograma'>
            <HoraCharla hora={'17:00'}/>
            <div>
                <DetalleCharla tema={'Puntos 7.'} />
                <p><b>7. Desarrollo acto electoral.</b></p>
                <p>Merienda.</p>
            </div>
        </div>


        <div className='gridPrograma'>
            <HoraCharla hora={'18:00'}/>
            <div>
                <DetalleCharla tema={'Puntos 15, 16 y 17.'} />
                <p>15. Documento Final.</p>
                <p>16. Proclamación de Autoridades.</p>
                <p>17. Cierre del Congreso.</p>
                <Disertante disertante={'Invitados especiales:'} cargo={'Ministra de Trabajo, Kelly Olmos; Secretario de Trabajo, Marcelo Bellotti; Directora Nacional de Asociaciones Sindicales, Mónica Rissotto; Secretario de General de la FEDUN, Daniel Ricci; Secretario General de la CONADU, Carlos De Feo; y Presidente de la FEDUA, Emiliano Ojea.'}/><br></br>
            </div>
        </div>


        <div className='gridPrograma' >
            <HoraCharla hora={'22:00'}/>
            <div>
                <DetalleCharla tema={'Cena - Cierre del Congreso.'} />
            </div>
        </div>
    </div>
    )
}

export default ItinerarioContainer
