import programa from '../img/programa.svg'
import informacion from '../img/info.svg'
import panelistas from '../img/image.svg'
import ubicacion from '../img/ubicacion.svg'
import '../App.css'
import { Link } from 'react-router-dom'

const IconosHome = () => { 
    return ( 
        <>
            <div className='gridHome'>
                <div className='m-1' style={{cursor:'pointer'}}>
                <Link to='/programa'> <img src={programa} alt="" className='p-1 icon-responsive'/> </Link>
                    <p className='poppins-italic poppins-bold is-size-5 has-text-black'>Programa</p>
                </div>

                <div className='m-1' style={{cursor:'pointer'}}>
                <Link to='/informacion'><img src={informacion} alt="" className='p-1 icon-responsive'/> </Link> 
                    <p className='poppins-italic poppins-bold is-size-5 has-text-black'>Convocatoria</p>
                </div>   
        
                <div className='m-1' style={{cursor:'pointer'}}>
                <a href='https://drive.google.com/file/d/1aGuxqKkS-KR8fuLTVMxFGDMuvpmB4yns/view?usp=sharing' target="_blank"> <img src={panelistas} alt="" className='p-1 icon-responsive'/></a> 
                    <p className='poppins-italic poppins-bold is-size-5 has-text-black'>Convenio Act.2023</p>
                </div>  
         
                <div className='m-1' style={{cursor:'pointer'}}>
                <Link to='/ubicacion'> <img src={ubicacion} alt="" className='p-1 icon-responsive'/> </Link> 
                    <p className='poppins-italic poppins-bold is-size-5 has-text-black'>Ubicación</p>
                </div>
            </div>
        </>
    )
}

export default IconosHome;

