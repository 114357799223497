import Encabezado from "../componentes/Encabezado";
import Title from "../componentes/Title";
import IconPage from "../componentes/IconPage";
import info from '../img/info.svg'
import imgInfo from '../img/imgInfo.png'
import Footer from "../componentes/Footer";
import IconosVolver from "../componentes/IconosVolver";
import '../App.css'

const Info = () => {


  return (
    <>
      <IconosVolver to={'/'} />
      <Encabezado />
      <IconPage image={info} indice={'Convocatoria al Congreso XLI'} />
      <div className="container">
        <div className="columns">
          <div className="column has-text-left mt-6 mb-6">
            <p className="mt-5 mb-5">
               <b>La Federación Argentina del Trabajador de las Universidades Nacionales, convoca al XLI Congreso Nacional Ordinario, denominado “Unidad Nodocente”</b>, el cual se realizará durante los días 11 y 12 de abril de 2023, en el “Hotel Evita”, sito en la R.P. 5, Km. 90,5, de la localidad de Santa Rosa de Calamuchita, en la Provincia de Córdoba; dando comienzo el mismo a las 10 horas, para tratar los siguientes puntos del:,
            </p>
            <p>
              <b>ORDEN DEL DIA</b>
              <ul>
                <li>1- Nominación de la Comisión de Poderes.</li>
                <li>2- Elección de dos Secretarías del Congreso.</li>
                <li>3- Elección de dos Congresales para firmar el Acta del Congreso.</li>
                <li>4- Lectura y Consideración del Acta del Congreso Anterior.</li>
                <li>5- Lectura y Consideración de la Memoria y el Balance del período 01/01/2022</li>
                <li>6- al 31/12/2022.</li>
                <li>7- Elección de autoridades de la Mesa Ejecutiva de FATUN para el período</li>
                <li>8- 2023-2027: Elección Junta Electoral, Junta Fiscalizadora del Acto Eleccionario, Renovación de Autoridades (Mesa Ejecutiva, Comisión Revisora de Cuentas y Tribunal de Disciplina) de la FATUN para el período 2023-2027.</li>
                <li>9- Desarrollo del Acto Eleccionario.</li>
                <li>10- Análisis y situación de las UUNN. Actualidad Nacional.</li>
                <li>11- Convenio Colectivo de Trabajo. Consideraciones Generales.</li>
                <li>12- Informe General de la Mesa Ejecutiva.</li>
                <li>13- Ponencias de las Asociaciones.</li>
                <li>14- Informe General de la Obra Social.</li>
                <li>15- Informe Complementario del Consejo Directivo.</li>
                <li>16- Designación de Miembros del Consejo Directivo.</li>
                <li>17- Documento Final.</li>
                <li>18- Proclamación de Autoridades.</li>
                <li>19- Cierre del Congreso.</li>
              </ul>
            </p>
          </div>
        </div>
      </div >


      <Footer texto={'federación argentina del trabajador de las universidades nacionales'} />


    </>
  )
}

export default Info
